<template>
  <div style="background-color: #f5f5f5">
    <div class="aaa">
      <div class="bbb">优户通网格果果许可及服务协议</div>

      <div class="top">首部及导言</div>

      <div class="top">欢迎使用优户通企业网格果果软件及服务！</div>

      <div class="top">为使用优户通企业网格果果软件（以下简称“本软件”）及服务，用户应当阅读并遵守《优户通企业网格果果软件许可及服务协议》（以下简称“本协议”），以及《优户通服务协议》《优户通网格果果软件许可及服务协议》等相关协议。请用户务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示用户注意。</div>

      <div class="top">除非用户已阅读并接受本协议所有条款，否则用户无权下载、安装或使用本软件及相关服务。用户的下载、安装、使用、获取网格果果服务管理帐号及网格果果最终用户帐号（以下简称：网格果果帐号）、登录等行为即视为用户已阅读并同意上述协议的约束。</div>

      <div class="top">如果用户未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。</div>

      <div class="top">一、协议的范围</div>

      <div class="top">1.1 协议适用主体范围</div>

      <div class="top">本协议是用户与优户通之间关于用户下载、安装、使用、复制本软件，以及使用优户通相关服务所订立的协议。“优户通”是指优户通公司及其相关服务可能存在的运营关联单位。“网格果果用户”是指注册、登录、使用网格果果软件及服务并获得管理权限的个人或组织，在本协议中更多地称为“你”。“最终用户”是指收到邀请后加入你所创建的网格果果的个人用户。前述“网格果果用户”及“最终用户”统称为“用户”或“所有用户”。</div>

      <div class="top">1.2 协议关系及冲突条款</div>

      <div class="top">本协议被视为《优户通服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</div>

      <div class="top">本协议内容同时包括优户通可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，你同样应当遵守。</div>

      <div class="top">二、关于本服务</div>

      <div class="top">2.1 本服务的内容</div>

      <div class="top">本服务内容是指优户通向用户提供的社区管理平台居民端（以下简称“网格果果”），支持单人、多人参与，为用户提供社区共治、安全等相关功能。</div>

      <div class="top">2.2 本服务的形式</div>

      <div class="top">2.2.1 用户使用本服务需要通过网格果果绑定自身的手机号码或者居住房屋信息，优户通将向用户提供网格果果的使用权限、使用功能和使用说明等服务，并尽应有之努力保障本服务的正常运行。</div>

      <div class="top">2.2.2 本服务中优户通网格果果客户端软件是用于微信平台上的小程序。</div>

      <div class="top">2.2.3你可以通过网格果果参与到社区的共治、安全管理等相关社区自治中。</div>

      <div class="top">2.3 本服务许可的范围</div>

      <div class="top">2.3.1 优户通给予用户一项不可转让的、非排他性的许可，以使用本软件。</div>

      <div class="top">2.3.2 你可以为使用本软件及服务的目的复制本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。</div>

      <div class="top">2.3.3 本条及本协议其他条款未明示授权的其他一切权利仍由优户通保留，用户在行使这些权利时须另外取得优户通的书面许可。优户通如果未行使前述任何权利，并不构成对该权利的放弃。</div>

      <div class="top">三、软件的获取</div>

      <div class="top">3.1 用户可以直接从微信上获取。</div>

      <div class="top">四、软件的安装与卸载</div>

      <div class="top">4.1 优户通可能为不同的终端设备开发了不同的软件版本，用户应当根据实际情况选择下载合适的版本进行安装。</div>

      <div class="top">4.2 下载安装程序后，用户需要按照该程序提示的步骤正确安装。</div>

      <div class="top">4.3 为提供更加优质、安全的服务，在本软件安装时优户通可能推荐用户安装其他软件，用户可以选择安装或不安装。</div>

      <div class="top">4.4 如果用户不再需要使用本软件或者需要安装新版软件，可以自行卸载。</div>

      <div class="top">五、软件的更新</div>

      <div class="top">5.1 为了改善用户体验、完善服务内容，优户通将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。</div>

      <div class="top">5.2 为了保证本软件及服务的安全性和功能的一致性，优户通有权不经向用户特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。</div>

      <div class="top">5.3 本软件新版本发布后，旧版本的软件可能无法使用。优户通不保证旧版本软件及相应的客户服务继续可用，请用户随时核对并下载最新版本。</div>

      <div class="top">5.4用户同意，优户通可能通过公告、更新日志等方式向用户通知软件更新情况，但优户通不需向用户特别通知。</div>

      <div class="top">六、用户信息及个人信息保护</div>

      <div class="top">6.1 保护用户信息是优户通的一项基本原则，优户通将会采取合理的措施保护用户的信息。除法律法规规定的情形外，未经用户许可优户通不会向第三方公开、透露用户信息。优户通对相关信息采用专业加密存储与传输方式，保障用户信息的安全。</div>

      <div class="top">6.2 用户在使用本服务的过程中，需要提供一些必要的个人信息，例如：为向用户提供帐号注册服务或进行用户身份识别，需要填写手机号码；通话功能需要用户授权访问手机通讯录等。若国家法律法规或政策有特殊规定的，用户需要提供真实的身份信息。若用户提供的信息不完整，则无法使用本服务或在使用过程中受到限制。</div>

      <div class="top">6.3 一般情况下，用户可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，用户可能无法修改注册时提供的初始注册信息及其他验证信息。</div>

      <div class="top">6.4 优户通将运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。</div>

      <div class="top">6.5你在上传和管理组织成员名称、照片、手机号等个人信息时，请确保你已经事先获得组织成员的明确同意，仅收集为运营及功能实现目的而必需的用户信息，且已充分告知组织成员相关数据收集的目的、范围及使用方式等。</div>

      <div class="top">6.6 网格果果用户应当建立信息安全保护制度并对最终用户进行信息安全教育，包括但不限于：将管理权限交予适当的人选；合理设定相关人员的管理权限；提醒或要求最终用户注意信息安全和帐号安全，防止帐号被他人使用而导致的信息泄露。</div>

      <div class="top">6.7 未经用户的同意，优户通不会向优户通以外的任何公司、组织和个人披露用户的个人信息，但法律法规另有规定的除外。</div>

      <div class="top">6.8 优户通非常重视对未成年人个人信息的保护。若用户是18周岁以下的未成年人，在使用优户通的服务前，应事先取得用户家长或法定监护人的书面同意。</div>

      <div class="top">6.9 就你使用本软件及服务过程中所产生的及你所收集或被提供的任何个人信息（“个人信息”），你应根据所有可适用法律法规使用本服务，并依约履行你在本协议下的义务。本协议所指的“可适用法律法规”是指你通过本服务所提供的信息、服务或前述信息、服务可被接收的国家、地区的法律、法规、规章、规范性文件、政策。你承诺：</div>

      <div class="top">6.9.1遵守不时修订的所有可适用的隐私和数据保护的法律法规，包括适用于个人信息的法律法规，并向我们提供必要信息以证明你已遵守前述法律法规及本协议约定；</div>

      <div class="top">6.9.2 仅就使用本软件及本服务之目的使用、处理用户的个人信息，除非最终用户另行授权或同意；</div>

      <div class="top">6.9.3明确、准确地向你的最终用户描述你所收集的个人信息以及如何使用、向第三方分享这些信息等；</div>

      <div class="top">6.9.4采取适宜的技术及系统的安全措施，以保护个人信息免遭未经授权访问或使用，并对前述安全措施的有效性进行定期测试；</div>

      <div class="top">6.9.5 确保所有有权访问个人信息的人员都受保密义务的约束；</div>

      <div class="top">6.9.6出现任何未经授权的访问或使用个人信息的情况下，及时向优户通、最终用户和相关数据保护机构汇报；</div>

      <div class="top">6.9.7尽可能协助优户通应对最终用户根据可适用法律法规行使其权利的要求。</div>

      <div class="top">6.10 更多有关个人信息和隐私保护的相关政策，请详见《网格果果隐私保护指引》</div>

      <div class="top">七、主权利义务条款</div>

      <div class="top">7.1 帐号使用规范</div>

      <div class="top">7.1.1 你在使用本服务前需要验证你的真实社区身份，为验证最终用户所属组织，保障网格果果帐号安全并获得提供服务所必要的用户信息，最终用户需通过手机号码及房屋信息验证后方能使用网格果果帐号。优户通有权根据用户需求或产品需要对帐号注册方式进行变更。关于你使用帐号的具体规则，请遵守《优户通软件许可及服务协议》《网格果果用户帐号使用规范》相关帐号服务使用协议以及优户通为此发布的专项规则。</div>

      <div class="top">7.1.2 网格果果帐号的所有权归优户通公司所有，用户完成申请注册手续后，仅获得网格果果帐号的使用权，用户不得赠与、借用、租用、转让或售卖网格果果帐号或者以其他方式许可他人使用。</div>

      <div class="top">7.1.3用户应当按照法律法规和服务要求，按相应页面的提示准确提供并及时更新用户的资料，以使之真实、及时，完整和准确。用户承诺不得冒充他人进行注册，不得未经许可为他人注册，不得以可能误导其他用户的方式注册帐号，不得使用可能侵犯他人权益的用户名进行注册（包括但不限于涉嫌商标权、名誉权侵权等），否则优户通有权注销该帐号。</div>

      <div class="top">7.1.4 用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需要对注册帐户以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。当在你怀疑他人在使用你的帐号时，你应立即通知优户通公司。</div>

      <div class="top">7.1.5 用户注册网格果果帐号后如果长期不登录该帐号，优户通有权回收该帐号，以免造成资源浪费，由此带来的任何损失均由用户自行承担。</div>

      <div class="top">7.1.6用户确认，在用户完成注册程序或以其他优户通允许的方式实际使用服务时，用户应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若用户不具备前述主体资格，请勿使用本服务，否则用户及用户的监护人应承担因此而导致的一切后果，且优户通有权注销（永久冻结）用户的账户，给优户通造成损失的，优户通有权向用户追偿。如你代表一家公司或其他法律主体进行注册或以其他优户通允许的方式实际使用本服务的，则你声明和保证，你有权使该公司或该法律主体受本协议的约束。</div>

      <div class="top">7.2 用户注意事项</div>

      <div class="top">7.2.1 用户理解并同意：为了向用户提供有效的服务，本软件会利用用户终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。</div>

      <div class="top">7.2.2 你理解并同意：本软件会让最终用户查询到本企业或组织内其他最终用户的信息，但管理员可以通过管理权限限制最终用户的信息查阅权限。在使用本服务管理你的最终用户信息时，你应当：</div>

      <div class="top">7.2.2.1 充分告知最终用户使用本软件及本服务对用户信息、内容相关影响的政策规定；</div>

      <div class="top">7.2.2.2 确保在使用本服务过程中对用户信息、内容的使用和处理遵从可适用的法律法规的要求;</div>

      <div class="top">7.2.2.3 应对并处理你与最终用户就用户信息、内容相关的，或因你未完全履行该条款所产生的所有争议及纠纷，并独立承担由此而产生的一切法律责任。</div>

      <div class="top">7.2.3 用户在使用本软件某一特定服务或功能时，可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），且该特定服务或功能也可能由其他主体提供，用户在使用该项服务或功能前请阅读并同意该服务或功能提供者的相关单独协议、业务规则。用户使用前述特定服务，即视为用户接受相关单独协议、业务规则。</div>

      <div class="top">7.2.4 用户理解并同意优户通将会尽其商业上的合理努力保障用户在本软件及服务中的数据存储安全，但是，优户通并不能就此提供完全保证，包括但不限于以下情形：</div>

      <div class="top">7.2.4.1 优户通不对用户在本软件及服务中相关数据的删除或储存失败负责；</div>

      <div class="top">7.2.4.2 优户通有权根据实际情况自行决定用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。用户可根据自己的需要自行备份本软件及服务中的相关数据；</div>

      <div class="top">7.2.4.3 如果用户停止使用本软件及服务或服务被终止、取消，优户通可以从服务器上永久地删除你的数据。服务停止、终止或取消后，优户通没有义务向用户返还任何数据。</div>

      <div class="top">7.2.5 用户在使用本软件及服务时，须自行承担如下来自优户通不可掌控的风险内容，包括但不限于：</div>

      <div class="top">7.2.5.1 由于不可抗拒因素可能引起的用户信息丢失、泄漏等风险；</div>

      <div class="top">7.2.5.2 用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；</div>

      <div class="top">7.2.5.3 用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；</div>

      <div class="top">7.2.5.4 用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</div>

      <div class="top">7.2.5.5 由于网络信号不稳定、网络带宽小等网络原因，所引起的优户通网格果果登录失败、资料同步不完整、页面打开速度慢等风险。</div>

      <div class="top">7.3 网格果果API</div>

      <div class="top">7.3.1 优户通在网格果果平台向你提供了对网格果果帐号功能进行开发的各项服务（以下简称“开发服务”），你应按照本协议和开发者文档等规范和技术要求自行开发，你应对使用该开发服务所产生的内容自行独立承担责任，如因此造成优户通或第三方损失的，你应当赔偿。</div>

      <div class="top">7.3.2 未经优户通书面许可，你不得将开发服务用于任何其他目的及用途，或委托、授权及泄漏给任何第三方为任何方式的使用。</div>

      <div class="top">7.3.3 你使用开发服务所进行的开发全过程除应符合网格果果开发文档的明确要求外，还应符合相关法律法规、技术规范标准以及优户通在技术、安全等方面的相关要求， 以确保相关网格果果帐号安全、稳定地运营。本协议使用规范同时包含本协议条款、网格果果相关开发文档以及其他相应接口的开发者文档，你使用开发服务应当同时遵守上述规范，否则优户通有权依照本协议采取处理措施。</div>

      <div class="top">7.3.4 你在使用开发服务过程中不得从事以下行为，也不得为以下行为提供便利，该等行为包括但不限于：</div>

      <div class="top">7.3.4.1 在未获得优户通书面许可的情况下，以任何方式使用优户通URL地址、本服务外的其他技术接口等；</div>

      <div class="top">7.3.4.2 为任何其他用户自动登录到网格果果提供代理身份验证凭据；</div>

      <div class="top">7.3.4.3 自动将浏览器窗口定向到其他网页；</div>

      <div class="top">7.3.4.4 公开表达或暗示你与优户通之间存在合作关系，包括但不限于相互持股、商业往来或合作关系等；</div>

      <div class="top">7.3.4.5 利用开发服务在你的网格果果帐号下开发任何形式的网络平台服务；</div>

      <div class="top">7.3.4.6 侵犯他人的合法权益，包括但不限于：著作权、商标权、专利权等知识产权及其他相关权益；</div>

      <div class="top">7.3.4.7 其他优户通认为不应该、不适当的行为或内容。</div>

      <div class="top">7.3.5 你使用开发服务通过网格果果帐号向其他用户提供服务的过程中，对于其他用户或最终用户数据的收集、保存、使用等必须满足以下要求：</div>

      <div class="top">7.3.5.1 必须事先获得其他用户或最终用户的明确同意，且仅应当收集为运营及功能实现目的而必需的用户数据，同时应当告知其他用户或最终用户相关数据收集的目的、范围及使用方式等，保障其他用户及最终用户的知情权和选择权；</div>

      <div class="top">7.3.5.2 你对收集的相关用户数据，必须采取必要的保护措施，防止用户数据被盗、泄漏等；</div>

      <div class="top">7.3.5.3 你在特定网格果果帐号建立的企业架构中收集的用户数据仅可以在该特定网格果果帐号的企业架构中使用，不得将其使用在该特定网格果果帐号的企业架构之外或为其他任何目的进行使用，也不得以任何方式将其提供给他人；</div>

      <div class="top">7.3.5.4 一旦你停止使用开发服务，或优户通基于任何原因终止你使用开发服务，你必须立即删除全部因使用该服务而获得的数据（包括各种备份），且不得再以任何方式进行使用；</div>

      <div class="top">7.3.5.5 你应自行对因使用开发服务而获得的各类数据和信息，采取合理、安全的技术措施，确保其安全性，并对自己的行为（包括但不限于自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。</div>

      <div class="top">7.4 第三方产品和服务</div>

      <div class="top">7.4.1 用户在使用本软件第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。优户通和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。</div>

      <div class="top">7.4.2 因用户使用本软件或要求优户通提供特定服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供（包括但不限于通话功能：可能通过电信运营商系统提供的通话服务），优户通不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与优户通无关，优户通不承担任何责任。</div>

      <div class="top">7.4.3 用户理解并同意，优户通有权决定将本软件作商业用途，包括但不限于开发、使用本软件的部分服务为第三方作推广等，优户通承诺在推广过程中严格按照本协议约定保护用户信息。</div>

      <div class="top">八、用户行为规范</div>

      <div class="top">8.1 信息内容规范</div>

      <div class="top">8.1.1 本条所述信息内容是指用户使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于网格果果帐号头像、名称、用户说明等注册信息，或文字、语音、图片等发送、回复、公告和相关链接页面，以及其他使用网格果果帐号或本软件及服务所产生的内容。</div>

      <div class="top">8.1.2 用户理解并同意，优户通一直致力于为用户提供文明健康、规范有序的网络环境，你不得利用网格果果帐号或本软件及服务制作、复制、发布、传播如下干扰网格果果软件正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：</div>

      <div class="top">8.1.2.1 发布、传送、传播、储存违反国家法律法规禁止的内容：</div>

      <div class="top">（1）违反宪法确定的基本原则的；</div>

      <div class="top">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>

      <div class="top">（3）损害国家荣誉和利益的；</div>

      <div class="top">（4）煽动民族仇恨、民族歧视，破坏民族团结的；</div>

      <div class="top">（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>

      <div class="top">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>

      <div class="top">（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>

      <div class="top">（8）侮辱或者诽谤他人，侵害他人合法权益的；</div>

      <div class="top">（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</div>

      <div class="top">（10）以非法民间组织名义活动的；</div>

      <div class="top">（11）不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；</div>

      <div class="top">（12）含有法律、行政法规禁止的其他内容的。</div>

      <div class="top">8.1.2.2 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</div>

      <div class="top">8.1.2.3 涉及他人隐私、个人信息或资料的；</div>

      <div class="top">8.1.2.4 发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；</div>

      <div class="top">8.1.2.5 其他违反法律法规、政策及公序良俗、社会公德或干扰网格果果正常运营和侵犯其他用户或第三方合法权益内容的信息。</div>

      <div class="top">8.2 软件使用规范</div>

      <div class="top">8.2.1 除非法律允许或优户通书面许可，用户使用本软件过程中不得从事下列行为：</div>

      <div class="top">8.2.1.1 删除本软件及其副本上关于著作权的信息；</div>

      <div class="top">8.2.1.2 对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；</div>

      <div class="top">8.2.1.3 对优户通拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</div>

      <div class="top">8.2.1.4 对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非优户通经授权的第三方工具/服务接入本软件和相关系统；</div>

      <div class="top">8.2.1.5 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</div>

      <div class="top">8.2.1.6 通过非优户通开发、授权的第三方软件、插件、外挂、系统，登录或使用优户通软件及服务，或制作、发布、传播上述工具；</div>

      <div class="top">8.2.1.7 自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；</div>

      <div class="top">8.2.1.8 其他未经优户通明示授权的行为。</div>

      <div class="top">8.2.2 用户理解并同意，基于用户体验、网格果果或相关服务平台运营安全、平台规则要求及健康发展等综合因素，优户通有权选择提供服务的对象，有权决定功能设置，有权决定功能开放、数据接口和相关数据披露的对象和范围。如果用户的行为存在以下情形，优户通有权视具体情况中止或终止提供本服务，包括但不限于：</div>

      <div class="top">8.2.2.1 违反法律法规、本协议规定或不符合优户通其他管理要求的；</div>

      <div class="top">8.2.2.2 从事影响本软件服务体验的行为；</div>

      <div class="top">8.2.2.3 继续使用可能存在安全隐患的，包括但不限于对用户自身或第三方人身、财产构成安全隐患的，对公共安全构成安全隐患的等情形。</div>

      <div class="top">8.3 服务运营规范</div>

      <div class="top">除非法律允许或优户通书面许可，用户使用本服务过程中不得从事下列行为：</div>

      <div class="top">8.3.1 提交、发布虚假信息，或冒充、利用他人名义的；</div>

      <div class="top">8.3.2 诱导其他用户点击链接页面或分享信息的；</div>

      <div class="top">8.3.3 虚构事实、隐瞒真相以误导、欺骗他人的；</div>

      <div class="top">8.3.4 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权益的；</div>

      <div class="top">8.3.5 未经优户通书面许可利用网格果果帐号和任何功能，进行推广或互相推广的；</div>

      <div class="top">8.3.6 利用网格果果帐号或本软件及服务从事任何违法犯罪活动的；</div>

      <div class="top">8.3.7 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</div>

      <div class="top">8.3.8 其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或优户通未明示授权的行为。</div>

      <div class="top">8.4 对自己行为负责</div>

      <div class="top">用户充分理解并同意，用户必须为自己网格果果帐号下的一切行为负责，包括用户所发表的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。优户通无法且不会对因前述风险而导致的任何损失或损害承担责任。</div>

      <div class="top">8.5 违约处理</div>

      <div class="top">8.5.1 如果优户通发现或收到他人举报或投诉用户违反本协议约定的，优户通有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。</div>

      <div class="top">8.5.2 用户理解并同意，优户通有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。</div>

      <div class="top">8.5.3 用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；优户通因此遭受损失的，用户也应当一并赔偿。</div>

      <div class="top">九、服务费用</div>

      <div class="top">9.1优户通保留本软件的任何服务或功能后续收费的权利。优户通有权提前10个工作日以公告形式通知用户收费标准，若用户继续使用则需按优户通公布的收费标准支付费用。</div>

      <div class="top">9.2优户通有权根据实际情况单方调整费用标准及收费方式，并提前10个工作日以公告形式通知你，而无需向用户特别通知或获得用户的事先同意，如用户不同意收费，用户应当立即停止服务的使用，否则使用则视为用户已同意并应当缴纳费用。</div>

      <div class="top">9.3用户应当自行支付使用本软件可能产生的上网费以及其他第三方收取的通讯费、信息费等。</div>

      <div class="top">十、终端安全责任</div>

      <div class="top">10.1 用户理解并同意，本软件同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁用户的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，用户应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。</div>

      <div class="top">10.2 用户不得制作、发布、使用、传播用于窃取网格果果帐号及他人个人信息、财产的恶意程序。</div>

      <div class="top">10.3 维护软件安全与正常使用是优户通和用户的共同责任，优户通将按照行业标准合理审慎地采取必要技术措施保护你的终端设备信息和数据安全，但是用户承认和同意优户通并不能就此提供完全保证。</div>

      <div class="top">10.4 在任何情况下，用户不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意优户通有关防范诈骗犯罪的提示。</div>

      <div class="top">十一、第三方软件或技术</div>

      <div class="top">11.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。</div>

      <div class="top">11.2 本软件如果使用了第三方的软件或技术，优户通将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。如果用户没有遵守这些要求，该第三方或者国家机关可能会对用户提起诉讼、罚款或采取其他制裁措施，并要求优户通给予协助，用户应当自行承担法律责任。</div>

      <div class="top">11.3 如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，优户通不承担任何责任。优户通不对第三方软件或技术提供客服支持，若用户需要获取支持，请与第三方联系。</div>

      <div class="top">十二、免责与责任限制</div>

      <div class="top">12.1 用户理解并同意：本软件及本服务按“如是”的状态提供，优户通对本服务不作任何明示或暗示的保证，包括但不限于对本服务的可适用性、持续性等。</div>

      <div class="top">12.2 用户理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断，因此而引发的损害及后果，优户通不承担任何责任。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴等以及社会事件如战争、动乱、政府行为等。</div>

      <div class="top">12.3 在适用法律允许的范围内，优户通在任何情况下（不论何种原因）均不承担如下责任：（A）任何间接的、故意的、惩罚性的、附带的、惩戒性的、特殊的或后果性的损害赔偿; （B）业务或机会损失; （C）收入损失; （D）利润损失; （E）商誉损失;（F）内容损失; 或（G）数据丢失。</div>

      <div class="top">十三、知识产权声明</div>

      <div class="top">13.1 优户通是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，优户通享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。</div>

      <div class="top">13.2未经优户通或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。</div>

      <div class="top">十四、其他</div>

      <div class="top">14.1 用户使用本软件即视为用户已阅读并同意受本协议的约束。优户通有权在必要时修改本协议条款。优户通可能会以包括但不限于在相关服务页面展示、网页公告、网页提示、电子邮箱、手机短信、常规的信件传送、用户注册使用本服务的帐号管理系统内发送站内信等方式中的一种或多种，向用户送达关于本服务的各种规则、通知、提示等信息，用户可以在本软件的最新版本中查阅相关协议条款。本协议条款变更后，如果用户继续使用本软件，即视为用户已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用本软件。</div>

      <div class="top">用户同意，任何由于电子邮箱、手机号码、通讯地址等信息错误或其他不可归咎于优户通的原因，导致用户未收到相关规则、通知、提示等信息的，均不影响该等信息对用户所产生的法律效力，且用户应受其约束，由此产生的一切后果及责任由用户自行承担。</div>

      <div class="top">14.2 本协议签订地为中华人民共和国浙江省杭州市滨江区。</div>

      <div class="top">14.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</div>

      <div class="top">14.4 若用户和优户通之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，用户同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</div>

      <div class="top">14.5 本协议所有条款的标题仅为阅读方便，本身并无实际含义，不能作为本协议含义解释的依据。</div>

      <div class="top">14.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</div>

      <div class="top">14.7 为方便阅读理解，本协议以中、英文书就，但具体协议条款内容均以本协议中文版本为准。</div>
    </div>
  </div>
  <!--pages/index/agreement.wxml-->


</template>

<script>
export default {
name: "agreement"
}
</script>

<style lang="scss" scoped>
.aaa{
  margin: 40px;
  background-color: #fff;
  padding: 40px;
  font-size: 24px;
}
.bbb{
  font-size: 60px;
  text-align: center;
}
.top{
  margin-top: 20px;
}
</style>
